import React from 'react';
import { ZFetch } from './tools/ZFetch';
const UserInfoContext = React.createContext({
  userinfo: {},
  setUserinfo: () => {}
});

export class UserInfoProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userinfo: {}, // 初始状态
      setUserinfo: this.setUserinfo, // 将更新函数绑定到state，以便下游组件可以调用
      updateUserInfo: this.updateUserInfo
    };
    this.updateUserInfo = this.updateUserInfo.bind(this);
  }

  setUserinfo = (userinfo) => {
    this.setState({userinfo});
  };

  updateUserInfo = () => {
    let api = '/api/AutoLogin'
    ZFetch(
      api,
      {},
      (data) => {
        if (!data) {
          console.warn('> ' + api + 'Failed!');
          return;
        }
        if (data.Code && data.Data && data.Data.UserInfo) {
          //更新用户信息到context
          this.setState({userinfo: data.Data.UserInfo});
        }
      },
      (err) => {
        console.warn('> update userinfo in context error:' + err.message);
      }
    );
  };

  render() {
    return <UserInfoContext.Provider value={{...this.state, updateUserInfo: this.updateUserInfo}}>{this.props.children}</UserInfoContext.Provider>;
    // return <UserInfoContext.Provider value={this.state}>{this.props.children}</UserInfoContext.Provider>;
  }
}

export default UserInfoContext;
