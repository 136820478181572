const Conf = {
    WebSocket: 'wss://' + window.location.host,
    ApiPre: 'https://' + window.location.host,
    OssBucket: 'syc-private',
    OssRegion: 'oss-cn-shanghai', //带oss-
    OssShareBucket: 'syc-public',
    OssShareDomain: 'syc-public.oss-cn-shanghai.aliyuncs.com',
    OssShareRegion: 'oss-cn-shanghai', //带oss-
    AvatarMin: '?x-oss-process=image/resize,m_fill,h_64,w_64',
    AvatarMid: '?x-oss-process=image/resize,m_fill,h_128,w_128',
    AvatarBig: '?x-oss-process=image/resize,m_fill,h_256,w_256',
    AvatarMax: '?x-oss-process=image/resize,m_fill,h_512,w_512',
    QuizTypes: {
        option: { 'text': '单选题', 'value': 'option' },
        options: { 'text': '多选题', 'value': 'options' },
        text: { 'text': '文字题', 'value': 'text' },
        io: { 'text': '代码输入输出题', 'value': 'io' },
        acc: { 'text': '分类结果预测(ACC)', 'value': 'acc' },
        r2: { 'text': '回归结果预测(R2)', 'value': 'r2' },
        f1: { 'text': '分类结果预测(F1)', 'value': 'f1' },
        mae: { 'text': '回归结果预测(MAE)', 'value': 'mae' },
    },
    // 公开试题界面的下拉选项
    PubQuizLang: {
        "py": {
            id: 1,
            title: "python",
        },
        "c++": {
            id: 2,
            title: "c++",
        },
    },
    PersonalQuizState: {
        "unsubmit": {
            id: 1,
            title: "未提交",
        },
        "bravo": {
            id: 2,
            title: "已满分",
        },
        "sad": {
            id: 3,
            title: "未满分",
        }
    },
    ShareType: {
        py: {
            id: 1,
            title: "py",
            desc: "python代码文件",
            extension: ".py",
        },
        md: {
            id: 2,
            title: "md",
            desc: "markdown文件",
            extension: ".md",
        },
        cpp: {
            id: 3,
            title: "cpp",
            desc: "cpp代码文件",
            extension: ".cpp",
        },
        ipynb: {
            id: 4,
            title: "ipynb",
            desc: "ipynb代码文件",
            extension: ".ipynb",
        },
    },
    PaperDefault: () => ({
        Title: '未标题',
        Desc: '',
        TsOpen: Date.now(),
        TsClose: Date.now() + 3600 * 24 * 1000,
        Public: false,
        Shuffle: false,
        PaperShareAble: false,
        CreateStandardType: false,
    }),

    CreateQuizDefault: () => {
        const now = new Date(); // 获取当前日期对象
        const month = now.getMonth() + 1; // 获取月份 (注意月份从0开始,所以需要加1)
        const date = now.getDate(); // 获取日期
    
        return {
            Title: `小测验 ${month}/${date}`,
            Desc: '',
            TsOpen: Date.now(),
            TsClose: Date.now() + 3600 * 24 * 365 * 1000,
            Public: false,
            Shuffle: false,
            PaperShareAble: false,
            CreateStandardType: false,
            SubmitOnlyOnce: false,
            QuizQuestionID: '',
            SubmitInterval: '无限制',
            UserSetAssigned: '',
        }
    },

    SubmitInterval: {
        '0': '无限制',
        '1': '一周',
        '2': '24小时',
        '3': '12小时',
        '4': '6小时',
        '5': '1小时',
        '6': '10分钟',
        '7': '1分钟'
    },

    QuizDefault: () => ({
        Title: '未标题',
        Desc: '',
        Explain: '',
        Shuffle:true,
        Answers: { 0: { Input: '', Output: '', Score: 100, Percent: 1 } },
        Type: 'option',
        Ktags: [],
        // 用于sigmoid函数来界定当结果是多少时, 分数应该是多少,
        // 例如当结果是0.1 时, 我想让分数为90分 那a应该是2 b应该是0, a,b中间用英文半角逗号分开
        Threshold: '',
    }),
    PaperAssignUncover: {
        Event: {
            'never': '不允许学生查看结果',
            'begin': '随时(考试开始后)',
            'end': '考试结束后',
        },
        Level: {
            '0': '只给总分和每题得分',
            '1': '给出每个选项详细评分',
            '2': '给出详细评分和答案',
            '3': '给出详细评分和答案和解析',
        },
    },
    AssignTypeMap: {
        'Uid': '试卷作者', 'UserAssign': '特别授权', 'UserSetAssign': '班级授权'
    },
    // 如果添加作业类型，记得增加/root/zerver/pkg/conf/conf.go 文件下的Suffix 
    ShareSetType: [".py", ".cpp", ".md", ".mp4", ".ipynb"],
    Type2Name: {
        '.py': '【Python分享(.py)】',
        '.cpp': '【C++分享(.cpp)】',
        '.mp4': '【CodeShow视频(.mp4)】',
        '.md': '【小论文(.md)】',
        '.ipynb': '【Notebook分享(.ipynb)】'
    },
    ShowThreshold: {
        'mae': true,
    },
    ShowPasteAnswer: {
        'mae': true,
        'f1': true,
        'r2': true,
        'acc': true,
    },
    ShowButton: {
        'Standard': true,
        'Lite': true,
        'Tutor': false,
        'HeadTeacher': false,
    }
}


export default Conf