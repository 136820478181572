import './App.css';
import React, {Suspense} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import GenRouters from './GenRouters';
import {teal, orange} from '@material-ui/core/colors/';

import {createTheme, ThemeProvider} from '@material-ui/core';
import {UserInfoProvider} from './UserContext';
import {PythonProvider} from 'react-py';
// import ChatMan from './components/ChatMan/ChatMan';

/*
根据屏幕分辨率设定字体
*/
function autoSetFontSize() {
  // let wid = window.screen.width
  let wid = document.documentElement.clientWidth;
  if (wid <= 600) {
    return 14;
  } else if (wid <= 1024) {
    return 8;
  } else {
    return 14;
  }
}
//自定义全局主题颜色，在下面createDom中全局使用
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: teal[500],
      light: teal[400],
      dark: teal[600],
      contrastText: '#FFF'
    },
    secondary: {
      main: orange[600],
      light: orange[500],
      dark: orange[700],
      contrastText: '#FFF'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 200
      }
    }
  },
  typography: {
    fontSize: autoSetFontSize(), //影响全局字体大小
    fontFamily: ['sans-serif', 'SimHei', 'monospace', 'SimSun', 'Arial', '-apple-system'].join(',')
  }
});

//---------------以上自定义样式主题----------

const RouterPathes = GenRouters();

let createDom = (k) => (
  <div className="App">
    <UserInfoProvider>
      <PythonProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <Router>
              <Switch>
                <Suspense fallback={<div className={'App-Suspense'}>Loading...</div>}>
                  {Object.keys(RouterPathes).map((key, index) => {
                    var dom = RouterPathes[key];
                    return <Route exact key={key} path={key} app={k} component={dom} />;
                    // var dom = RouterPathes[key];
                    // return (
                    //   <div key={index}>
                    //     <Route exact path={key} app={k} component={dom} />
                    //     <ChatMan/>
                    //   </div>
                    // );
                  })}
                </Suspense>
              </Switch>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </PythonProvider>
    </UserInfoProvider>
  </div>
);

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: 'shiyancang.cn',
      author: 'zhyuzh'
    };
  }
  render() {
    return createDom(this);
  }
}
