//使用延迟加载
import React from 'react';
const Welcome = React.lazy(() => import("./pages/Welcome/Welcome"))
const Test = React.lazy(() => import("./pages/Test/Test"))
const Home = React.lazy(() => import("./pages/Home/Home"))
const Login = React.lazy(() => import("./pages/Login/Login"))
const Register = React.lazy(() => import("./pages/Register/Register"))
const ForgotPw = React.lazy(() => import("./pages/ForgotPw/ForgotPw"))
const Editor = React.lazy(() => import("./pages/Editor/Editor"))
const MdViewer = React.lazy(() => import("./pages/MdViewer/MdViewer"))
const Share = React.lazy(() => import("./pages/Share/Share"))
const MdEditor = React.lazy(() => import("./pages/MdEditor/MdEditor"))
const UserHome = React.lazy(() => import("./pages/UserHome/UserHome"))
const Paper = React.lazy(() => import("./pages/Paper/Paper"))
const Admin = React.lazy(() => import("./pages/Admin/Admin"))
const PaperCopy = React.lazy(() => import("./pages/PaperCopy/PaperCopy"))
const Rating = React.lazy(() => import("./pages/Rating/Rating"))


function GenRouters() {
    //全体路由对象，所有页面
    let router = {
        '/Welcome': Welcome,
        '/Home': Home,
        '/Login': Login,
        '/Register': Register,
        '/ForgotPw': ForgotPw,
        '/Editor': Editor,
        '/MdViewer': MdViewer,
        '/Admin': Admin,
        '/Share': Share,
        '/MdEditor': MdEditor,
        '/UserHome': UserHome,
        '/Paper': Paper,
        '/PaperCopy': PaperCopy,
        '/Test': Test,
        '/Rating': Rating,
        '/': Welcome,
    }
    //如果是首页则自动跳到Welcome地址
    let uhash = window.location.hash
    if (uhash === '#/' || uhash === '' || uhash === '#') {
        window.location.hash = 'Welcome'
    }
    if (uhash.substr(0, 3) === '#/?') {
        window.location.hash = router['/'] + window.location.hash.substr(2)
    }
    return router
}

export default GenRouters