/*
发送信息,地址自动从Conf读取
api eg.'/api/Logout'
payloadObj  eg.{uid:133,uname:'zh'}
okHandler eg.(data)=>()
errHandler eg.(err)=>() default is null
type default is 'POST'
*/
import Conf from "../conf/Conf"

export function ZFetch(api, payloadObj, okHandler, errHandler = null, type = 'POST', useApiPre = true) {
    const requestOptions = {
        body: JSON.stringify(payloadObj),
        cache: 'no-cache',
        withCredentials: false,
        headers: {
            'user-agent': 'Mozilla/4.0 MDN Example',
            'content-type': 'text/plain'
        },
        method: type,
        mode: 'cors',
        redirect: 'follow',
        referrer: 'no-referrer',
    }

    let apifix = useApiPre ? Conf.ApiPre + api : api

    apifix = apifix.startsWith("http://") ? `https://${apifix.substring(7)}` : apifix

    fetch(apifix, requestOptions)
        .then(response => response.json())
        .catch(err => {
            if (errHandler) {
                errHandler(err)
            } else {
                console.error('> ZFetch Error:', err)
            }
        })
        .then(data => {
            if (okHandler) {
                okHandler(data)
            } else {
                console.log('> ZFetch OK:', data)
            }
        });
}


export function ZFetchFile(fpath, okHandler, errHandler = null) {
    const requestOptions = {
        cache: 'no-cache',
        headers: {
            'user-agent': 'Mozilla/4.0 MDN Example',
            'content-type': 'text/plain'
        },
        method: 'GET',
        mode: 'cors',
        redirect: 'follow',
        referrer: 'no-referrer',
    }

    fpath = fpath.startsWith("http://") ? `https://${fpath.substring(7)}` : fpath

    fetch(fpath, requestOptions)
        .then(response => response.text())
        .catch(err => {
            if (errHandler) {
                errHandler(err)
            } else {
                console.error('> ZFetch Error:', err)
            }
        })
        .then(txt => {
            if (okHandler) {
                okHandler(txt)
            } else {
                console.log('> ZFetch OK:', txt)
            }
        });
}

const exportobj = { ZFetch, ZFetchFile }
export default exportobj